<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title>
        Importar base de datos
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-3">
        <v-row>
          <v-col cols="12" class="d-flex py-3">
            <v-btn
              color="green darken-3"
              small
              dark
              @click="$refs.input.click()"
            >
              <v-icon small left>fas fa-folder-open</v-icon>
              Seleccionar archivo
              <input
                ref="input"
                type="file"
                class="d-none"
                :accept="tipos_validos.join(',')"
                @change="validar_base($event)"
              >
            </v-btn>
            <!-- descargar modelo -->
            <download-excel
              name="Modelo de base de datos.xls"
              :data="[{a:'',b:'',c:'',d:'',e:'',f:'',g:'',h:'',i:''}]"
              :fields="{
                'IVR': 'a',
                'EJECUTIVO': 'b',
                'PUNTO DE VENTA': 'c',
                'CIUDAD': 'd',
                'NRO DE CI': 'e',
                'NOMBRE': 'f',
                'APELLIDO': 'g',
                'FOR DE PAG': 'h',
                'LINEA': 'i'
              }"
            >
              <v-btn
                color="indigo"
                class="ml-2"
                style="min-width: 30px"
                title="Descargar modelo de la base"
                small
                dark
              >
                <v-icon small>fas fa-file-download</v-icon>
              </v-btn>
            </download-excel>
          </v-col>
          <v-col cols="12" class="py-0 body-2 font-weight-bold">
            <v-divider></v-divider>
            <div
              v-if="nombre"
              class="mt-2"
            >
              <v-icon
                color="green darken-3"
                class="ml-1"
                style="margin-bottom: 2px"
                small
                left
              >
                fas fa-file-excel
              </v-icon>
              {{ nombre }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          nombre="Importar"
          icono="fas fa-upload"
          :texto="`¿Está seguro que desea <strong>importar</strong> el archivo <strong>${nombre}</strong>?`"
          :disabled="json == null"
          @action="importar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>  
</template>

<script>
import { tipos_archivos } from '../../util/utils'
import { read, utils } from 'xlsx'
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      json: null,
      nombre: '',
      tipos_validos: ['.xls', '.xlsx', '.ods'] // los tipos de archivos están en /util/utils.js
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        this.json = null
        this.nombre = ''
      }
    }
  },
  methods: {
    async importar () {
      this.dialog = false
      this.$store.state.loading = true
      await this.$store.dispatch('grupopy/importar_base', { base: this.json })
        .then((res) => {
          this.$swal.fire({
            icon: 'success',
            title: res.message
          })
        })
        .catch(error => {
          this.$swal.fire({
            icon: 'error',
            title: error.message
          })
        })
      this.$store.state.loading = false
    },
    convertir_json (file) {
      try {
        const reader = new FileReader()
        reader.onload = (e) => {
          const bytes = e.target.result
          const excel = read(bytes, { type: 'binary', cellDates: true })
          // obtiene el nombre de la primer hoja
          const hoja_nombre = excel.SheetNames[0]
          // obtiene la primer hoja
          const hoja = excel.Sheets[hoja_nombre]
          // obtiene la data de la primer hoja
          const data = utils.sheet_to_json(hoja, { header: 1 })

          // cicla por cada row de la primer hoja (excluyendo los headers)
          this.json = []
          for (let i = 1; i < data.length; i++) {
            const row = data[i]
            // solo si la row tiene algun dato lo agrega al json
            if (row.length > 0) {
              this.json.push({
                ivr: row[0],
                ejecutivo_nombre: row[1],
                ptovta_nombre: row[2],
                cuidad: row[3],
                responsable_documento: row[4],
                responsable_nombre: row[5]
              })
            }
          }
          
          if (this.json.length > 0) {
            this.nombre = file.name
          }
          else {
            this.json = null
            this.nombre = ''
            this.$store.dispatch('show_snackbar', {
              text: 'El archivo cargado no contiene datos',
              color: 'warning',
              timeout: 3000
            })
          }
        }
        reader.readAsBinaryString(file)
      }
      catch (error) {
        this.json = null
        this.nombre = ''
        this.$store.dispatch('show_snackbar', {
          text: 'Ocurrio un error inesperado al convertir el archivo a JSON: ' + error,
          color: 'error',
          timeout: 4000
        })
      }
    },
    async validar_base (event) {
      const files = event.target.files
      if (files) {
        // obtiene el archivo (en este caso solo puede subir 1)
        const file = files[0]
        const tipo = file.type

        await this.$store.dispatch('tiempo_subida', file.size)

        // busca en el array de tipos_archivos el tipo de archivo del archivo que subio
        const tipo_archivo = tipos_archivos.find(a => a.mime == tipo)
        // verifica que exista el tipo de archivo en el array
        if (tipo_archivo) {
          // verifica que pertenezca por lo menos a un tipo de extension existente en el array de tipos
          if (this.tipos_validos.find(a => a == tipo_archivo.extension)) {
            // el archivo que subio es correcto, pasa a convertirlo a json
            this.convertir_json(file)
          }
          else {
            // subio un archivo con una extension no valida
            this.$store.dispatch('show_snackbar', {
              text: 'Tipo de archivo no válido',
              color: 'error'
            })
          }
        }
        else {
          // subio un tipo de archivo que no esta registrado en el array tipos_archivos
          this.$store.dispatch('show_snackbar', {
            text: 'Tipo de archivo no válido para el sistema',
            color: 'error'
          })
        }

        // limpia el input de archivos
        this.$refs.input.value = ''
      }
    }
  }
}
</script>