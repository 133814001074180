<template>
  <v-dialog
    v-model="dialog"
    width="700"
    scrollable
  >
    <v-card>
      <v-card-title>
        Importar liquidaciones
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="pt-8">
          <!-- PICKER DE PERIODO -->
          <v-col cols="6" sm="3" class="pt-0 pb-sm-0">
            <PeriodoPicker
              v-model="periodo"
              :label="true"
              :hideDetails="true"
              @selecciono="verificar_periodo"
            />
          </v-col>
          <v-col cols="12" sm="6" class="d-flex align-center py-0" style="font-size: 18px">
            {{ $store.state.loading ? '' : (periodo_valido ? `Archivos a importar para ${moment(parseDate(`01/${periodo}`)).locale('es').format('MMMM')}` : 'Seleccione un periodo') }}
          </v-col>
          <!-- DATA TABLE DE ARCHIVOS SUBIDOS -->
          <v-col cols="12" class="py-sm-1 py-0">
            <v-data-table
              class="elevation-2 mt-2"
              :headers="headers"
              :items="archivos"
              hide-default-footer
              dense
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  color="error"
                  title="Quitar"
                  class="mr-2"
                  small
                  @click="quitar(item)"
                >
                  fas fa-trash
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="300"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay archivos disponibles
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
          <v-col>
            <v-row>
              <!-- BOTON SUBIR ARCHIVO PREPAGOS -->
              <v-col cols="12" sm="4" class="d-flex justify-center px-4">
                <v-row no-gutters>
                  <v-col cols="10">
                    <v-btn
                      color="green darken-3"
                      title="Cargar archivo"
                      :disabled="archivos.find(a => a.type == 'PREPAGOS') != undefined || !periodo_valido"
                      :dark="!(archivos.find(a => a.type == 'PREPAGOS') != undefined  || !periodo_valido)"
                      block
                      small
                      @click="$refs.inputPrepagos.click()"
                    >
                      <v-icon small left>fas fa-file-upload</v-icon>
                      Prepagos
                      <input
                        ref="inputPrepagos"
                        type="file"
                        class="d-none"
                        :accept="tipos_validos.join(',')"
                        @change="validar_prepagos($event)"
                      >
                    </v-btn>
                  </v-col>
                  <v-col cols="2" style="padding-left: 6px">
                    <!-- descargar modelo -->
                    <download-excel
                      name="Modelo de archivo liquidaciones de prepagos.xls"
                      :data="[{a:'',b:'',c:'',d:'',e:'',f:'',g:'',h:'',i:'',j:'',k:'',l:'',m:'',n:'',o:''}]"
                      :fields="{
                        'LINEA': 'a',
                        'CONTRATO': 'b',
                        'FEC_ACTIV': 'c',
                        'FEC_APROB': 'd',
                        'FECHA_PRIMERA_CARGA': 'e',
                        'PDV': 'f',
                        'MTO_COMI_PDV': 'g',
                        'COD_APROB': 'h',
                        'MTO_PRIMERA_CARGA': 'i',
                        'RECARGA': 'j',
                        'COMI_PRIMERA_CARGA': 'k',
                        'COMI_RECARGAS': 'l',
                        'BONI_PORTA': 'm',
                        'MES': 'n',
                        'COMISION': 'o'
                      }"
                    >
                      <v-btn
                        color="indigo"
                        style="min-width: 30px"
                        title="Descargar modelo de prepagos"
                        small
                        dark
                      >
                        <v-icon small>fas fa-file-download</v-icon>
                      </v-btn>
                    </download-excel>
                  </v-col>
                </v-row>
              </v-col>
              <!-- BOTON SUBIR ARCHIVO OTROS CONCEPTOS -->
              <v-col cols="12" sm="4" class="d-flex justify-center px-4 px-sm-2 py-0 py-sm-3">
                <v-row no-gutters>
                  <v-col cols="10">
                    <v-btn
                      color="green darken-3"
                      title="Cargar archivo"
                      :disabled="archivos.find(a => a.type == 'OTROS CONCEPTOS') != undefined || !periodo_valido"
                      :dark="!(archivos.find(a => a.type == 'OTROS CONCEPTOS') != undefined || !periodo_valido)"
                      block
                      small
                      @click="$refs.inputOtros.click()"
                    >
                      <v-icon small left>fas fa-file-upload</v-icon>
                      Otros conceptos
                      <input
                        ref="inputOtros"
                        type="file"
                        class="d-none"
                        :accept="tipos_validos.join(',')"
                        @change="validar_otros($event)"
                      >
                    </v-btn>
                  </v-col>
                  <v-col cols="2" style="padding-left: 6px">
                    <!-- descargar modelo -->
                    <download-excel
                      name="Modelo de archivo liquidaciones de otros conceptos.xls"
                      :data="[{a:'',b:'',c:'',d:'',e:'',f:''}]"
                      :fields="{
                        'IVR': 'a',
                        'CANTIDAD': 'b',
                        'CONCEPTO': 'c',
                        'MONTO': 'd',
                        'TOTAL': 'e',
                        'PRODUCTO': 'f'
                      }"
                    >
                      <v-btn
                        color="indigo"
                        style="min-width: 30px"
                        title="Descargar modelo de otros conceptos"
                        small
                        dark
                      >
                        <v-icon small>fas fa-file-download</v-icon>
                      </v-btn>
                    </download-excel>
                  </v-col>
                </v-row>
              </v-col>
              <!-- BOTON SUBIR ARCHIVO INTERNET -->
              <v-col cols="12" sm="4" class="d-flex justify-center pl-4 pr-4 pr-sm-8">
                <v-row no-gutters>
                  <v-col cols="10">
                    <v-btn
                      color="green darken-3"
                      title="Cargar Archivo"
                      :disabled="archivos.find(a => a.type == 'INTERNET') != undefined || !periodo_valido"
                      :dark="!(archivos.find(a => a.type == 'INTERNET') != undefined || !periodo_valido)"
                      block
                      small
                      @click="$refs.inputInternet.click()"
                    >
                      <v-icon small left>fas fa-file-upload</v-icon>
                      Internet
                      <input
                        ref="inputInternet"
                        type="file"
                        class="d-none"
                        :accept="tipos_validos.join(',')"
                        @change="validar_internet($event)"
                      >
                    </v-btn>
                  </v-col>
                  <v-col cols="2" style="padding-left: 6px">
                    <!-- descargar modelo -->
                    <download-excel
                      name="Modelo de archivo liquidaciones de internet.xls"
                      :data="[{a:'',b:'',c:'',d:'',e:'',f:''}]"
                      :fields="{
                        'NUM_LINE': 'a',
                        'DES_PROD': 'b',
                        'FEC_ACTIV': 'c',
                        'FEC_APROB': 'd',
                        'COMISION': 'e',
                        'COD_USUA_PDV': 'f'
                      }"
                    >
                      <v-btn
                        color="indigo"
                        style="min-width: 30px"
                        title="Descargar modelo de internet"
                        small
                        dark
                      >
                        <v-icon small>fas fa-file-download</v-icon>
                      </v-btn>
                    </download-excel>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          nombre="Importar"
          icono="fas fa-upload"
          :texto="`¿Está seguro que desea <strong>importar</strong> las liquidaciones para el periodo <strong>${periodo}</strong>?`"
          :disabled="archivos.find(a => a.type == 'PREPAGOS') == undefined || archivos.find(a => a.type == 'OTROS CONCEPTOS') == undefined"
          @action="importar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>  
</template>

<script>
import { parseDate, tipos_archivos } from '../../util/utils'
import { read, utils } from 'xlsx'
import moment from 'moment'
import BtnConfirmar from '../util/BtnConfirmar'
import PeriodoPicker from '../util/PeriodoPicker'

export default {
  data () {
    return {
      parseDate: parseDate,
      moment: moment,
      periodo: null,
      periodo_valido: false,
      archivos: [],
      headers: [
        { text: 'Nombre', value: 'name', sortable: false },
        { text: 'Tipo', value: 'type', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false, align: 'center' }
      ],
      tipos_validos: ['.xls', '.xlsx', '.ods'] // los tipos de archivos están en /util/utils.js
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    PeriodoPicker
  },
  watch: {
    dialog (val) {
      if (val) {
        this.periodo = null
        this.periodo_valido = false
        this.archivos = []
      }
    }
  },
  methods: {
    async verificar_periodo (periodo) {
      this.periodo_valido = false
      this.$store.state.loading = true
      await this.$store.dispatch('grupopy/get_cantidad_liqui', periodo)
        .then((res) => {
          if (res.data == 0) {
            this.periodo = periodo
            this.periodo_valido = true
          }
          else {
            this.periodo = null
            this.$store.dispatch('show_snackbar', {
              text: 'Ya existe una liqudiación para el periodo seleccionado',
              color: 'warning'
            })
          }
        })
        .catch(error => {
          this.periodo = null
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async importar () {
      this.$store.state.loading = true
      await this.$store.dispatch('grupopy/importar_archivos', {
        periodo: this.periodo,
        prepagos: this.archivos.find(a => a.type == 'PREPAGOS') ? this.archivos.find(a => a.type == 'PREPAGOS').data : [],
        otros: this.archivos.find(a => a.type == 'OTROS CONCEPTOS') ? this.archivos.find(a => a.type == 'OTROS CONCEPTOS').data : [],
        internet: this.archivos.find(a => a.type == 'INTERNET') ? this.archivos.find(a => a.type == 'INTERNET').data : []
      })
        .then((res) => {
          this.dialog = false
          this.$swal.fire({
            icon: 'success',
            title: res.message
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    quitar (item) {
      const index = this.archivos.indexOf(item)
      this.archivos.splice(index, 1)
    },
    async validar_prepagos (event) {
      const files = event.target.files
      if (files) {
        // obtiene el archivo (en este caso solo puede subir 1)
        const file = files[0]
        const tipo = file.type

        await this.$store.dispatch('tiempo_subida', file.size)

        // valdia el tipo de archivo y si es correcto pasa a convertirlo a json
        if (this.validar_archivo(tipo)) {
          try {
            const reader = new FileReader()
            reader.onload = (e) => {
              const bytes = e.target.result
              const excel = read(bytes, { type: 'binary', cellDates: true })
              // obtiene el nombre de la primer hoja
              const hoja_nombre = excel.SheetNames[0]
              // obtiene la primer hoja
              const hoja = excel.Sheets[hoja_nombre]
              // obtiene la data de la primer hoja
              const data = utils.sheet_to_json(hoja, { header: 1 })
              
              // cicla por cada row de la primer hoja (excluyendo los headers)
              let prepagos = []
              for (let i = 1; i < data.length; i++) {
                const row = data[i]
                // solo si la row tiene algun dato lo agrega al json
                if (row.length > 0) {
                  let fecha_activacion = ''
                  let fecha_aprobacion = ''
                  let fecha_primera_carga = ''
                  // si las fechas tienen formato de fecha las pasa a dd/mm/aaaa, sino las deja como están
                  try {
                    fecha_activacion = row[2].getUTCDate().toString().padStart(2, '0') + '/' + (row[2].getMonth() + 1).toString().padStart(2, '0') + '/' + row[2].getFullYear()
                  } catch (error) {
                    fecha_activacion = row[2]
                  }
                  try {
                    fecha_aprobacion = row[3].getUTCDate().toString().padStart(2, '0') + '/' + (row[3].getMonth() + 1).toString().padStart(2, '0') + '/' + row[3].getFullYear()
                  } catch (error) {
                    fecha_aprobacion = row[3]
                  }
                  try {
                    fecha_primera_carga = row[4].getUTCDate().toString().padStart(2, '0') + '/' + (row[4].getMonth() + 1).toString().padStart(2, '0') + '/' + row[4].getFullYear()
                  } catch (error) {
                    fecha_primera_carga = row[4]
                  }
                  prepagos.push({
                    linea: row[0],
                    contrato: row[1],
                    fecha_activacion: fecha_activacion,
                    fecha_aprobacion: fecha_aprobacion,
                    fecha_primera_carga: fecha_primera_carga,
                    pdv: row[5],
                    monto_comision_pdv: row[6],
                    codigo_aprobacion: row[7],
                    monto_primera_carga: row[8],
                    recarga: row[9],
                    comision_primera_recarga: row[10],
                    comision_recargas: row[11],
                    bonificacion_portabilidad: row[12],
                    mes: row[13],
                    comision: row[14]
                  })
                }
              }
              
              if (prepagos.length > 0) {
                this.archivos.push({
                  name: file.name,
                  type: 'PREPAGOS',
                  data: prepagos
                })
              }
              else {
                this.$store.dispatch('show_snackbar', {
                  text: 'El archivo cargado no contiene datos',
                  color: 'warning',
                  timeout: 3000
                })
              }
            }
            reader.readAsBinaryString(file)
          } catch (error) {
            this.$store.dispatch('show_snackbar', {
              text: 'Ocurrio un error inesperado al convertir el archivo de prepagos a JSON: ' + error,
              color: 'error',
              timeout: 4000
            })
          }
        }

        // limpia el input de archivos
        this.$refs.inputPrepagos.value = ''
      }
    },
    async validar_otros (event) {
      const files = event.target.files
      if (files) {
        // obtiene el archivo (en este caso solo puede subir 1)
        const file = files[0]
        const tipo = file.type

        await this.$store.dispatch('tiempo_subida', file.size)

        // valdia el tipo de archivo y si es correcto pasa a convertirlo a json
        if (this.validar_archivo(tipo)) {
          try {
            const reader = new FileReader()
            reader.onload = (e) => {
              const bytes = e.target.result
              const excel = read(bytes, { type: 'binary', cellDates: true })
              // obtiene el nombre de la primer hoja
              const hoja_nombre = excel.SheetNames[0]
              // obtiene la primer hoja
              const hoja = excel.Sheets[hoja_nombre]
              // obtiene la data de la primer hoja
              const data = utils.sheet_to_json(hoja, { header: 1 })

              // cicla por cada row de la primer hoja (excluyendo los headers)
              let otros = []
              for (let i = 1; i < data.length; i++) {
                const row = data[i]
                // solo si la row tiene algun dato lo agrega al json
                if (row.length > 0) {
                  otros.push({
                    ivr: row[0],
                    unidad: row[1],
                    concepto: row[2],
                    monto: row[3],
                    importe: row[4],
                    producto: row[5]
                  })
                }
              }
              
              if (otros.length > 0) {
                this.archivos.push({
                  name: file.name,
                  type: 'OTROS CONCEPTOS',
                  data: otros
                })
              }
              else {
                this.$store.dispatch('show_snackbar', {
                  text: 'El archivo cargado no contiene datos',
                  color: 'warning',
                  timeout: 3000
                })
              }
            }
            reader.readAsBinaryString(file)
          } catch (error) {
            this.$store.dispatch('show_snackbar', {
              text: 'Ocurrio un error inesperado al convertir el archivo de otros conceptos a JSON: ' + error,
              color: 'error',
              timeout: 4000
            })
          }
        }

        // limpia el input de archivos
        this.$refs.inputOtros.value = ''
      }
    },
    async validar_internet (event) {
      const files = event.target.files
      if (files) {
        // obtiene el archivo (en este caso solo puede subir 1)
        const file = files[0]
        const tipo = file.type

        await this.$store.dispatch('tiempo_subida', file.size)

        // valdia el tipo de archivo y si es correcto pasa a convertirlo a json
        if (this.validar_archivo(tipo)) {
          try {
            const reader = new FileReader()
            reader.onload = (e) => {
              const bytes = e.target.result
              const excel = read(bytes, { type: 'binary', cellDates: true })
              // obtiene el nombre de la primer hoja
              const hoja_nombre = excel.SheetNames[0]
              // obtiene la primer hoja
              const hoja = excel.Sheets[hoja_nombre]
              // obtiene la data de la primer hoja
              const data = utils.sheet_to_json(hoja, { header: 1 })
              
              // cicla por cada row de la primer hoja (excluyendo los headers)
              let internet = []
              for (let i = 1; i < data.length; i++) {
                const row = data[i]
                // solo si la row tiene algun dato lo agrega al json
                if (row.length > 0) {
                  let fecha_activacion = ''
                  let fecha_aprobacion = ''
                  // si las fechas tienen formato de fecha las pasa a dd/mm/aaaa, sino las deja como están
                  try {
                    fecha_activacion = row[2].getUTCDate().toString().padStart(2, '0') + '/' + (row[2].getMonth() + 1).toString().padStart(2, '0') + '/' + row[2].getFullYear()
                  } catch (error) {
                    fecha_activacion = row[2]
                  }
                  try {
                    fecha_aprobacion = row[3].getUTCDate().toString().padStart(2, '0') + '/' + (row[3].getMonth() + 1).toString().padStart(2, '0') + '/' + row[3].getFullYear()
                  } catch (error) {
                    fecha_aprobacion = row[3]
                  }
                  internet.push({
                    liena: row[0],
                    descripcion_producto: row[1],
                    fecha_activacion: fecha_activacion,
                    fecha_aprobacion: fecha_aprobacion,
                    comision: row[4],
                    codigo_usuario_pdv: row[5]
                  })
                }
              }
              
              if (internet.length > 0) {
                this.archivos.push({
                  name: file.name,
                  type: 'INTERNET',
                  data: internet
                })
              }
              else {
                this.$store.dispatch('show_snackbar', {
                  text: 'El archivo cargado no contiene datos',
                  color: 'warning',
                  timeout: 3000
                })
              }
            }
            reader.readAsBinaryString(file)
          } catch (error) {
            this.$store.dispatch('show_snackbar', {
              text: 'Ocurrio un error inesperado al convertir el archivo de interntet a JSON: ' + error,
              color: 'error',
              timeout: 4000
            })
          }
        }

        // limpia el input de archivos
        this.$refs.inputInternet.value = ''
      }
    },
    validar_archivo (tipo) {
      // busca en el array de tipos_archivos el tipo de archivo del archivo que subio
      const tipo_archivo = tipos_archivos.find(a => a.mime == tipo)
      // verifica que exista el tipo de archivo en el array
      if (tipo_archivo) {
        // verifica que pertenezca por lo menos a un tipo de extension existente en el array de tipos
        if (this.tipos_validos.find(a => a == tipo_archivo.extension)) {
          // el archivo que subio es correcto
          return true
        }
        else {
          // subio un archivo con una extension no valida
          this.$store.dispatch('show_snackbar', {
            text: 'Tipo de archivo no válido',
            color: 'error'
          })
          return false
        }
      }
      else {
        // subio un tipo de archivo que no esta registrado en el array tipos_archivos
        this.$store.dispatch('show_snackbar', {
          text: 'Tipo de archivo no válido para el sistema',
          color: 'error'
        })
        return false
      }
    }
  }
}
</script>