var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Importar base de datos "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-3"},[_c('v-row',[_c('v-col',{staticClass:"d-flex py-3",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"green darken-3","small":"","dark":""},on:{"click":function($event){return _vm.$refs.input.click()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-folder-open")]),_vm._v(" Seleccionar archivo "),_c('input',{ref:"input",staticClass:"d-none",attrs:{"type":"file","accept":_vm.tipos_validos.join(',')},on:{"change":function($event){return _vm.validar_base($event)}}})],1),_c('download-excel',{attrs:{"name":"Modelo de base de datos.xls","data":[{a:'',b:'',c:'',d:'',e:'',f:'',g:'',h:'',i:''}],"fields":{
              'IVR': 'a',
              'EJECUTIVO': 'b',
              'PUNTO DE VENTA': 'c',
              'CIUDAD': 'd',
              'NRO DE CI': 'e',
              'NOMBRE': 'f',
              'APELLIDO': 'g',
              'FOR DE PAG': 'h',
              'LINEA': 'i'
            }}},[_c('v-btn',{staticClass:"ml-2",staticStyle:{"min-width":"30px"},attrs:{"color":"indigo","title":"Descargar modelo de la base","small":"","dark":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-file-download")])],1)],1)],1),_c('v-col',{staticClass:"py-0 body-2 font-weight-bold",attrs:{"cols":"12"}},[_c('v-divider'),(_vm.nombre)?_c('div',{staticClass:"mt-2"},[_c('v-icon',{staticClass:"ml-1",staticStyle:{"margin-bottom":"2px"},attrs:{"color":"green darken-3","small":"","left":""}},[_vm._v(" fas fa-file-excel ")]),_vm._v(" "+_vm._s(_vm.nombre)+" ")],1):_vm._e()],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end pb-4"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('BtnConfirmar',{attrs:{"nombre":"Importar","icono":"fas fa-upload","texto":`¿Está seguro que desea <strong>importar</strong> el archivo <strong>${_vm.nombre}</strong>?`,"disabled":_vm.json == null},on:{"action":function($event){return _vm.importar()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }