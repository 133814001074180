<template>
  <v-dialog
    v-model="dialog"
    width="700"
    scrollable
  >
    <v-card>
      <v-card-title>
        Historial de liquidaciones
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          class="cebra"
          sort-by="num_liqui"
          :loading="load"
          :items="liquidaciones"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          sort-desc
          dense
        >
          <template v-slot:[`item.num_liqui`]="{ item }">
            {{ item.estado == 3 ? item.num_liqui : '' }}
          </template>
          <template v-slot:[`item.estado_nombre`]="{ item }">
            <v-icon
              :color="item.estado_color"
              small
              left
            >
              {{ item.estado_icono }}
            </v-icon>
            {{ item.estado_nombre }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="item.estado == 3"
              color="info"
              title="Ver liquidación"
              class="mr-2"
              small
              @click="ver(item)"
            >
              fas fa-eye
            </v-icon>
            <v-icon
              v-if="item.estado == 4"
              color="info"
              title="Ver mensaje de error"
              class="mr-2"
              small
              @click="ver_error(item)"
            >
              fas fa-info-circle
            </v-icon>
            <BtnConfirmar
              v-if="item.elimina == 1"
              icono="fas fa-trash"
              color="error"
              :texto="`¿Está seguro que desea <strong>eliminar</strong> las liquidaciones del periodo <strong>${item.periodo}</strong>?`"
              :icon_button="true"
              :small="true"
              @action="eliminar_liqui(item)"
            />
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="secondary"
          class="mr-2"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      liquidaciones: [],
      headers: [
        { text: 'Numero', value: 'num_liqui', sortable: false, align: 'end' },
        { text: 'Periodo', value: 'periodo', sortable: false },
        { text: 'Fecha importación', value: 'fecha_importacion', sortable: false },
        { text: 'Estado', value: 'estado_nombre', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false, align: 'center' }
      ]
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    async dialog (val) {
      if (val) {
        await this.buscar()
      }
    }
  },
  methods: {
    async eliminar_liqui (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('grupopy/anular_liquidacion', item.num_liqui)
        .then((res) => {
          this.dialog = false
          this.$swal.fire({
            icon: 'success',
            title: res.message
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    ver (item) {
      this.dialog = false
      this.$emit('ver_liqui', item.num_liqui)
    },
    ver_error (item) {
      this.$swal.fire({
        icon: 'warning',
        text: item.msj_error
      })
    },
    async buscar () {
      this.load = true
      this.liquidaciones = []
      await this.$store.dispatch('grupopy/get_liquidaciones_headers')
        .then((res) => {
          this.liquidaciones = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    }
  }
}
</script>