<template>
  <v-dialog
    v-model="dialog"
    width="350"
  >
    <v-card>
      <v-card-title>
        Imprimir detalle x {{ p_ivr ? 'IVR' : 'supervisor'}}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="d-flex justify-center pb-1 pt-6">
        <v-form @submit.prevent="imprimir()" ref="form" class="d-flex justify-center">
          <v-text-field
            v-model="codigo"
            style="width: 120px; margin-top: 2px;"
            type="number"
            :label="p_ivr ? 'IVR' : 'Código'"
            :rules="[rules.required]"
            validate-on-blur
            autofocus
            outlined
            dense
          ></v-text-field>
          <v-btn
            color="info"
            class="ml-3"
            type="submit"
          >
            <v-icon small left>fas fa-print</v-icon>
            Imprimir
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <PDFViewer
      v-model="dialog_pdf"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_nombre"
    />
  </v-dialog>
</template>

<script>
import { reporteParaguay } from '../../util/plantillas/pdfs'
import PDFViewer from '../../util/plantillas/PDFViewer'

export default {
  data () {
    return {
      codigo: null,
      pdf: null,
      pdf_nombre: '',
      dialog_pdf: false,
      rules: {
        required: value => !!value || 'Campo requerido'
      }
    }
  },
  props: {
    value: Boolean,
    p_num_liqui: Number,
    p_ivr: Boolean  // indica si se debe imprimir detalle x ivr (true) o detalle por supervisor (false)
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    PDFViewer
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.codigo = null
      } else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async imprimir () {
      if (this.$refs.form.validate()) {
        const codigo = this.codigo
        let data = { num_liqui: this.p_num_liqui }
        if (this.p_ivr) {
          data.ivr = this.codigo
        } else {
          data.codigo = this.codigo
        }
        this.dialog = false
        this.$store.state.loading = true
        await this.$store.dispatch('grupopy/get_datos_imprimir', data)
          .then(async (res) => {
            if (res.data.length > 0) {
              this.pdf = await reporteParaguay(res.data)
              this.pdf_nombre = `Liquidación Nº ${this.p_num_liqui} - ${this.p_ivr ? 'IVR' : 'Supervisor'} ${codigo}`
              this.dialog_pdf = true
            }
            else {
              this.$swal.fire({
                icon: 'warning',
                title: 'No se encontraron liquidaciones para los filtros seleccionados'
              })
            }
          })
          .catch(error => {
            this.$swal.fire({
              icon: 'error',
              title: error.error,
              text: error.message
            })
          })
        this.$store.state.loading = false
      }
    }
  }
}
</script>