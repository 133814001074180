<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            title="Importar liquidación"
            small
            fab
            @click="dialog_nueva = true"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-btn
            style="position: absolute; top: -15px; right: 110px; z-index: 1"
            color="indigo"
            title="Historial de liquidaciones"
            small
            dark
            fab
            @click="dialog_historial = true"
          >
            <v-icon>far fa-clock</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="12" sm="6" md="3" xl="2" class="py-1 pr-md-0">
                  <v-row>
                    <v-col cols="6" md="7">
                      Periodo
                      <PeriodoPicker
                        v-model="filtro.periodo"
                        :clearable="true"
                        @selecciono="buscar_liquidacion"
                      />
                    </v-col>
                    <v-col cols="6" md="5" class="px-md-0">
                      Nº Liquidación
                      <v-text-field
                        v-model="filtro.num_liqui"
                        type="number"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1 ml-md-6">
                  <v-row>
                    <v-col cols="3" sm="4" class="pr-0">
                      Código
                      <v-text-field
                        v-model.trim="filtro.codigo"
                        type="number"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col class="pr-md-0">
                      Supervisor
                      <v-text-field
                        v-model.trim="filtro.supervisor"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1 ml-md-3">
                  <v-row>
                    <v-col cols="3" sm="4" class="pr-0">
                      IVR
                      <v-text-field
                        v-model.trim="filtro.ivr"
                        type="number"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col class="pr-md-0">
                      Punto venta
                      <v-text-field
                        v-model.trim="filtro.ptovta"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="supervisor_nombre"
        :items="liquidaciones"
        :headers="headers"
        :loading="load"
        :footer-props="{'items-per-page-options':[10, 30, 50, 100],'show-current-page':true, 'show-first-last-page':true}"
        dense
      >
        <!-- filtro dentro del datatable y botones para descargar -->
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="12" sm="3" class="d-flex justify-start align-center">
              <DownloadDataTable
                :data="data"
                :name="`Liquidación ${num_liqui}`"
              />
            </v-col>
            <v-col cols="12" sm="6" class="d-flex justify-sm-center align-center mt-2 mt-sm-0">
              <v-btn
                color="info"
                title="Imprimir detalle x supervisor"
                small
                dark
                @click="dialog_imprimir = true; ivr = false"
              >
                <v-icon small left>fas fa-print</v-icon>
                Detalle X Sup
              </v-btn>
              <v-btn
                color="info"
                class="ml-2"
                title="Imprimir detalle x ivr"
                small
                dark
                @click="dialog_imprimir = true; ivr = true"
              >
                <v-icon small left>fas fa-print</v-icon>
                Detalle X IVR
              </v-btn>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex justify-sm-end align-center mt-2 mt-sm-0">
              <v-btn
                color="success"
                small
                dark
                @click="dialog_base = true"
              >
                <v-icon small left>fas fa-file-upload</v-icon>
                Importar base
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <!-- template para formatear montos -->
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <!-- ultima row para el total -->
        <template slot="body.append">
          <tr class="font-weight-bold">
            <th style="font-size: 14px">Totales</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th style="font-size: 14px" class="text-right">
              {{ formatMoney(subtotal) }}
            </th>
            <th></th>
            <th style="font-size: 14px" class="text-right">
              {{ formatMoney(total) }}
            </th>
            <th></th>
          </tr>
        </template>
        <!-- acciones -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            title="Ver detalle"
            color="info"
            small
            @click="detalle(item)"
          >
            fas fa-eye
          </v-icon>
        </template>
        <!-- no data -->
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <DetalleLiquidacionIVR
      v-model="dialog_detalle"
      :p_periodo="periodo"
      :p_liqui="liqui"
    />
    <ImportarLiquidacionesIVR
      v-model="dialog_nueva"
    />
    <ImportarBaseDatos
      v-model="dialog_base"
    />
    <HisotiralLiquidacionesIVR
      v-model="dialog_historial"
      @ver_liqui="ver_liquidacion"
    />
    <ImprimirDetallesLiqui
      v-model="dialog_imprimir"
      :p_num_liqui="parseInt(num_liqui)"
      :p_ivr="ivr"
    />
  </v-row>
</template>

<script>
import { format_money } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'
import PeriodoPicker from '../../components/util/PeriodoPicker'
import DownloadDataTable from '../../components/util/DownloadDataTable'
import DetalleLiquidacionIVR from '../../components/grupopy/DetalleLiquidacionIVR'
import ImportarLiquidacionesIVR from '../../components/grupopy/ImportarLiquidacionesIVR'
import ImportarBaseDatos from '../../components/grupopy/ImportarBaseDatos'
import HisotiralLiquidacionesIVR from '../../components/grupopy/HisotiralLiquidacionesIVR'
import ImprimirDetallesLiqui from '../../components/grupopy/ImprimirDetallesLiqui'

export default {
  data () {
    return {
      formatMoney: format_money,
      panel: 0,
      total: 0,
      subtotal: 0,
      periodo: null,
      num_liqui: null,
      load: false,
      ivr: false,
      dialog_historial: false,
      dialog_imprimir: false,
      dialog_detalle: false,
      dialog_nueva: false,
      dialog_base: false,
      data: [],
      liquidaciones: [],
      headers: [
        { text: 'Código', value: 'supervisor_codigo', align: 'end' },
        { text: 'Supervisor', value: 'supervisor_nombre' },
        { text: 'IVR', value: 'ivr', align: 'end' },
        { text: 'Punto venta', value: 'ptovta_nombre', divider: true },
        { text: 'Prepagos', value: 'prepagos', align: 'end', formatter: format_money },
        { text: 'Internet', value: 'internet', align: 'end', formatter: format_money },
        { text: 'Otros', value: 'otros', align: 'end', divider: true, formatter: format_money },
        { text: 'Subtotal', value: 'subtotal', align: 'end', formatter: format_money },
        { text: 'IVA (10%)', value: 'iva', align: 'end', formatter: format_money },
        { text: 'Total', value: 'total', align: 'end', formatter: format_money },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false, filterable: false }
      ],
      liqui: {},
      filtro: {
        periodo: null,
        num_liqui: null,
        codigo: null,
        supervisor: null,
        ivr: null,
        ptovta: null
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('grupopy/get_ultima_liquidacion')
      .then(async (res) => {
        this.filtro.num_liqui = res.data
        await this.buscar()
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })
    this.$store.state.loading = false
  },
  components: {
    BtnFiltro,
    PeriodoPicker,
    DownloadDataTable,
    DetalleLiquidacionIVR,
    ImportarLiquidacionesIVR,
    ImportarBaseDatos,
    HisotiralLiquidacionesIVR,
    ImprimirDetallesLiqui
  },
  methods: {
    async ver_liquidacion (num_liqui) {
      this.filtro.num_liqui = num_liqui
      await this.buscar()
    },
    async detalle (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('grupopy/get_detalle_liquidacion', {
        num_liqui: item.num_liqui,
        ivr: item.ivr
      })
        .then((res) => {
          if (res.size != 0) {
            this.liqui = JSON.parse(JSON.stringify(item))
            this.liqui.arr_prepagos = res.data.prepagos
            this.liqui.arr_internet = res.data.internet
            this.liqui.arr_otros = res.data.otros
            this.dialog_detalle = true
          }
          else {
            this.$store.dispatch('show_snackbar', {
            text: 'No se encontraron detalles para el registro seleccionado',
            color: 'warning'
          })
          }
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      const liqui = this.filtro.num_liqui
      if (!liqui) {
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar un Nº de liquidación',
          color: 'warning'
        })
        return
      }
      this.total = 0
      this.subtotal = 0
      this.periodo = null
      this.num_liqui = null
      this.liquidaciones = []
      this.data = []
      this.load = true
      await this.$store.dispatch('grupopy/get_liquidaciones', this.filtro)
        .then(async (res) => {
          this.periodo = res.periodo
          this.num_liqui = JSON.stringify(liqui)
          this.liquidaciones = res.data
          this.filtro.periodo = res.periodo
          // genera la data para descargar
          this.liquidaciones.forEach(liqui => {
            this.data.push({
              'SUPERVISOR CÓDIGO': liqui.supervisor_codigo,
              'SUPERVISOR NOMBRE': liqui.supervisor_nombre,
              'IVR': liqui.ivr,
              'PUNTO VENTA': liqui.ptovta_nombre,
              'TOTAL PREPAGOS': liqui.prepagos.toString().replaceAll('.', ','),
              'TOTAL INTERNET': liqui.internet.toString().replaceAll('.', ','),
              'TOTAL OTROS': liqui.otros.toString().replaceAll('.', ','),
              'SUBTOTAL': liqui.subtotal.toString().replaceAll('.', ','),
              'IVA': liqui.iva.toString().replaceAll('.', ','),
              'TOTAL': liqui.total.toString().replaceAll('.', ',')
            })
          })

        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.calcular_totales()
      this.load = false
    },
    async buscar_liquidacion (periodo) {
      this.$store.state.loading = true
      this.filtro.periodo = periodo
      await this.$store.dispatch('grupopy/get_liquidacion_por_periodo', periodo)
        .then(async (res) => {
          this.filtro.num_liqui = res.data
          this.filtro.codigo = null
          this.filtro.supervisor = null
          this.filtro.ivr = null
          this.filtro.ptovta = null
          await this.buscar()
        })
        .catch(error => {
          this.total = 0
          this.subtotal = 0
          this.periodo = null
          this.num_liqui = null
          this.liquidaciones = []
          this.data = []
          this.filtro.num_liqui = null
          this.filtro.codigo = null
          this.filtro.supervisor = null
          this.filtro.ivr = null
          this.filtro.ptovta = null
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    calcular_totales () {
      this.liquidaciones.forEach(liqui => {
        this.total += liqui.total
        this.subtotal += liqui.subtotal
      })
    },
    limpiar () {
      this.filtro = {
        periodo: null,
        num_liqui: null,
        codigo: null,
        supervisor: null,
        ivr: null,
        ptovta: null
      }
    }
  }
}
</script>